<template>
  <div>
    <Header></Header>
    <div class="section preSection ">
      <div class="container">
        <div class="tags animate">
          <b-icon icon="flag-fill" variant="warning"></b-icon>
          HOT TAGS:
          <el-tag v-for="(t,o) in tags" :key="o" :type="colors[o%5-1]" class="tag">{{ t.tag }}</el-tag>
          <el-link>换一批</el-link>
        </div>
        <div class="searchArea animate" data-delay="200">
          <b-icon icon="search" variant="warning"></b-icon>
          SEARCH FOR BLOGS:
          <el-autocomplete
            v-model="state"
            :fetch-suggestions="querySearchAsync"
            placeholder="请输入内容"
            @select="handleSelect"
            class="searchInput"
          ></el-autocomplete>
          <el-button type="primary" style="margin-left: 20px;width: 100px">搜 索</el-button>
        </div>
        <el-row :gutter="20">
          <el-col :md="8" :sm="24" style="margin: 20px 0">
            <el-card>
              <el-badge value="hot" class="item">
                <h3 style="margin: 1px">热点话题:</h3>
              </el-badge>
              <div v-for="(hot,i) in hots" :key="i">
                <p>
                  {{ i + 1 }}、
                  <el-link @click="goBlogDetails(hot.id)">{{ hot.title }}</el-link>
                  <small style="margin-left: 10px">
                    <b-icon icon="hand-thumbs-up"></b-icon>
                    33251</small>
                </p>
              </div>
            </el-card>
          </el-col>
          <el-col :md="16" :sm="24" style="margin: 20px 0">
            <div class="block">
              <el-carousel height="350px">
                <el-carousel-item>
                  <img src="https://cos.mooc.huangzhaokun.top/moocImg/owl01.png" alt="" style="width: 100%">
                </el-carousel-item>
                <el-carousel-item>
                  <img src="https://cos.mooc.huangzhaokun.top/moocImg/owl02.png" alt="" style="width: 100%">
                </el-carousel-item>
              </el-carousel>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="bg-light section " data-ani="fadeIn"
         style="background: url(https://cos.mooc.huangzhaokun.top/moocImg/undraw_ideas_re_7twj.svg);background-position: 0 100%;background-repeat: no-repeat;background-size: 400px">
      <div class="container animate animated" data-ani="fadeIn">
        <h2 style="text-align: right" class="heading">教师博客精选！<i class="divider-left"></i></h2>
        <el-row :gutter="20">
          <el-col :md="8" :offset="8" :sm="24">
            <el-card>
              <div v-for="(hot,i) in hots" :key="i">
                <p>
                  {{ i + 1 }}、
                  <el-link @click="goBlogDetails(hot.id)">{{ hot.title }}</el-link>
                  <small style="margin-left: 10px">
                    <b-icon icon="hand-thumbs-up"></b-icon>
                    33251</small>
                </p>
              </div>
            </el-card>
          </el-col>
          <el-col :md="8">
            <el-card>
              <div v-for="(hot,i) in hots" :key="i">
                <p>
                  {{ i + 1 }}、
                  <el-link @click="goBlogDetails(hot.id)">{{ hot.title }}</el-link>
                  <small style="margin-left: 10px">
                    <b-icon icon="hand-thumbs-up"></b-icon>
                    33251</small>
                </p>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="section animate " data-ani="fadeIn"
         style="background-image:linear-gradient(to left, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)), url(https://cos.mooc.huangzhaokun.top/moocImg/quotes-bckg.jpg);
background-size: 95%;
background-repeat: no-repeat;
height: 250px">
      <div class="container">
        <div style="margin-top: 50px; float: right;text-align: center;width:50%;">
          <el-button type="primary" @click="dialogVisible = true" icon="el-icon-plus" circle></el-button>
          <h3>创建新的博客</h3>
        </div>
      </div>
    </div>
    <div class="section bg-light webSection">
      <div class="container">
        <el-row :gutter="20">
          <el-col :md="16" :sm="24">
            <el-row :gutter="20">
              <!--                style="background: url(https://cos.mooc.huangzhaokun.top/moocImg/undraw_static_website_re_x70h.svg);-->
              <!--background-repeat: no-repeat;background-size: 60%;background-position: center; height: 300px"-->
              <div>
                <div style="margin-top: 50px">
                  <el-carousel :interval="4000" type="card" height="300px">
                    <el-carousel-item v-for="item in 3" :key="item">
                      <el-card class="animate">
                        <div v-for="(hot,i) in hots" :key="i">
                          <p>
                            {{ i + 1 }}、
                            <el-link @click="goBlogDetails(hot.id)">{{ hot.title }}</el-link>
                            <small style="margin-left: 10px">
                              <b-icon icon="hand-thumbs-up"></b-icon>
                              33251</small>
                          </p>
                        </div>
                      </el-card>
                    </el-carousel-item>
                  </el-carousel>
                </div>
              </div>
            </el-row>
          </el-col>
          <el-col :md="8" :sm="24"><h2 style="text-align: right">Web设计与编程专区</h2></el-col>
        </el-row>
      </div>
    </div>
    <div class="section javaSection">
      <div class="container">
        <div>
          <el-row :gutter="20">
            <el-col :md="8" :sm="24">
              <h2 class="heading">JAVA专区<span class="divider-left"></span></h2>
              <img style="width: 280px;position: absolute;top: 135px"
                   src="https://cos.mooc.huangzhaokun.top/moocImg/QQ%E5%9B%BE%E7%89%8720220309153024.jpg" alt="">
            </el-col>
            <el-col :md="16" :sm="24">
              <el-card class="javaCard card1 animate" data-ani="bounceIn" data-delay="400">
                <strong>进阶</strong>
                <div v-for="(hot,i) in hots" :key="i">
                  <p>
                    {{ i + 1 }}、
                    <el-link @click="goBlogDetails(hot.id)">{{ hot.title }}</el-link>
                    <small style="margin-left: 10px">
                      <b-icon icon="hand-thumbs-up"></b-icon>
                      33251</small>
                  </p>
                </div>
              </el-card>
              <el-card class="javaCard card2 animate" data-ani="bounceIn" data-delay="800">
                <strong>框架</strong>
                <div v-for="(hot,i) in hots" :key="i">
                  <p>
                    {{ i + 1 }}、
                    <el-link @click="goBlogDetails(hot.id)">{{ hot.title }}</el-link>
                    <small style="margin-left: 10px">
                      <b-icon icon="hand-thumbs-up"></b-icon>
                      33251</small>
                  </p>
                </div>
              </el-card>
              <el-card class="javaCard card3 animate" data-ani="bounceIn">
                <strong>基础</strong>
                <div v-for="(hot,i) in hots" :key="i">
                  <p>
                    {{ i + 1 }}、
                    <el-link @click="goBlogDetails(hot.id)">{{ hot.title }}</el-link>
                    <small style="margin-left: 10px">
                      <b-icon icon="hand-thumbs-up"></b-icon>
                      33251</small>
                  </p>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <Footer></Footer>
    <el-dialog
      title="新建Blog"
      :visible.sync="dialogVisible"
      width="40%">
      <CreateBlog></CreateBlog>
    </el-dialog>
  </div>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import axios from 'axios'
import CreateBlog from '@/components/BlogTools/CreateBlog'
import { handleScroll } from '@/assets/api/animate'

export default {
  name: 'Blogs',
  components: {
    CreateBlog,
    Footer,
    Header
  },
  data () {
    return {
      form: {
        title: ''
      },
      tags: [],
      rules: {
        title: [
          {
            required: true,
            message: '请输入标题',
            trigger: 'blur'
          },
          {
            min: 3,
            max: 15,
            message: '长度在 3 到 15 个字符',
            trigger: 'blur'
          }
        ]
      },
      dialogVisible: false,
      colors: ['success', 'info', 'warning', 'danger', 'primary'],
      state: [],
      restaurants: [],
      hots: [{
        title: 'java-日期',
        id: 1500011
      }, {
        title: 'java-异常',
        id: 1500013
      }, {
        title: 'java-枚举类',
        id: 1500014
      }, {
        title: 'java-泛型',
        id: 1500015
      }, {
        title: 'javaList',
        id: 1500016
      }]
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.handleAnimate()
      addEventListener('scroll', handleScroll)
      addEventListener('scroll', this.handleAnimate)
    })
  },
  destroyed () {
    removeEventListener('scroll', handleScroll)
    removeEventListener('scroll', this.handleAnimate)
  },
  created () {
    this.getTags()
  },
  methods: {
    getTags () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/blogTag/showTagAndUsageCountOrderByUsageCount',
        params: {
          currentPage: 1,
          pageSize: 10
        }
      }).then(res => {
        if (res.data.status) {
          this.tags = res.data.data.content
        }
      })
    },
    goBlogDetails (blogId) {
      this.$router.push({
        name: 'BlogDetails',
        params: {
          blogId: blogId
        }
      })
    },
    getElementToPageTop (el) {
      if (el.parentElement) {
        return this.getElementToPageTop(el.parentElement) + el.offsetTop
      }
      return el.offsetTop
    },
    handleAnimate () {
      const top = pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      const vh = document.documentElement.clientHeight
      const dom = document.querySelectorAll('.animate');
      [].slice.call(dom).forEach(v => {
        v.style.opacity = 0
        if (top + 0.5 * vh > this.getElementToPageTop(v) / 3.09) {
          if (v.dataset.delay) {
            setTimeout(() => {
              v.style.opacity = 1
              v.classList.add('animated')
              if (v.dataset.ani) {
                v.classList.add(v.dataset.ani)
              } else {
                v.classList.add('fadeIn')
              }
            }, v.dataset.delay)
          } else {
            v.style.opacity = 1
            v.classList.add('animated')
            if (v.dataset.ani) {
              v.classList.add(v.dataset.ani)
            } else {
              v.classList.add('fadeIn')
            }
          }
        }
        // else {
        //   v.classList.remove(v.dataset.ani)
        //   v.style.opacity = 0
        // }
      })
    },
    querySearchAsync (queryString, cb) {
      const restaurants = this.restaurants
      const results = queryString ? restaurants.filter(this.createStateFilter(queryString)) : restaurants

      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        cb(results)
      }, 3000 * Math.random())
    },
    createStateFilter (queryString) {
      return (state) => {
        return (state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0)
      }
    },
    handleSelect (item) {
      console.log(item)
    },
    createBlog () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/blog/createBlog',
        params: {
          title: this.form.title
        }
      }).then(res => {
        if (res.data.status) {
          this.$message.success(res.data.message)
          this.$router.push({
            name: 'BlogEdit',
            params: { blogId: res.data.data.id }
          })
        }
      })
    }
  }
}
</script>

<style scoped>
.preSection {
  background: url(https://cos.mooc.huangzhaokun.top/moocImg/blogBgC.png);
  background-repeat: no-repeat;
  background-position: 0 65%;
  background-size: 100%;
  height: 100vh;
  padding: 100px 0;
  box-sizing: border-box;
}

.tags {
  margin: 20px 30px;
}

.tag {
  margin: 0 5px;
}

.searchArea {
  margin: 30px 30px;
}

.searchInput {
  margin-left: 20px;
  width: 400px;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.section {
  padding: 80px 0;
}

.heading .divider-left, .heading .divider-center {
  height: 4px;
  width: 70px;
  background: #4587d9;
  display: block;
}

.heading .divider-left {
  margin: 10px 0 -2px;
}

.javaCard {
  position: absolute;
  width: 320px;
}

.card1 {
  z-index: 1;
  top: 90%;
  right: 25%;
}

.card3 {
  z-index: 0;
  top: 180%;
  right: 50%;
}

.card2 {
  z-index: 2;
  top: 0;
  right: 0;
}

.card1.card2.card3 :hover {
  z-index: 10;
}

.javaSection {
  display: block;
  height: 580px;
  background: url(https://cos.mooc.huangzhaokun.top/moocImg/jiantou.jpg);
  background-repeat: no-repeat;
  background-size: 400px;
  background-position: 90% 90%;

}

.webSection {
  display: block;
  height: 380px;
  background: url(https://cos.mooc.huangzhaokun.top/moocImg/nxyqd.jpg);
  background-repeat: no-repeat;
  background-size: 240px;
  background-position: 88% 90%;
}

@media (max-width: 767px) {
  .javaCard.card1.card2.card3 {
    position: relative;
    left: inherit !important;
    top: inherit !important;
    max-width: 100%;
  }
}

</style>
