<template>
  <div>
    <el-form ref="form" :rules="rules" :model="form" label-width="80px">
      <el-form-item label="标题" prop="title">
        <el-input v-model="form.title"></el-input>
      </el-form-item>
      <el-form-item>
        <el-tag
          :key="tag"
          v-for="tag in dynamicTags"
          closable
          :disable-transitions="false"
          @close="handleClose(tag)">
          {{ tag }}
        </el-tag>
        <el-input
          class="input-new-tag"
          v-if="inputVisible"
          v-model="inputValue"
          ref="saveTagInput"
          size="small"
          @keyup.enter.native="handleInputConfirm"
          @blur="handleInputConfirm"
        >
        </el-input>
        <el-button v-else class="button-new-tag" size="small" @click="showInput">+ New Tag</el-button>
      </el-form-item>
      <el-form-item>
        <div style="text-align: center">
          <el-button type="primary" @click="createBlog" style="width: 150px">立即创建</el-button>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'CreateBlog',
  data () {
    return {
      form: {
        title: ''
      },
      dynamicTags: [],
      inputVisible: false,
      inputValue: '',
      rules: {
        title: [
          {
            required: true,
            message: '请输入标题',
            trigger: 'blur'
          },
          {
            min: 3,
            max: 15,
            message: '长度在 3 到 15 个字符',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    handleClose (tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1)
    },
    showInput () {
      this.inputVisible = true
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus()
      })
    },
    handleInputConfirm () {
      const inputValue = this.inputValue
      if (inputValue) {
        this.dynamicTags.push(inputValue)
      }
      this.inputVisible = false
      this.inputValue = ''
    },
    createBlog () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/blog/createBlog',
        params: {
          title: this.form.title
        }
      }).then(res => {
        if (res.data.status) {
          const id = res.data.data.id
          this.dynamicTags.forEach(tag => {
            this.createTag(id, tag)
          })
          this.$message.success(res.data.message)
          this.$router.push({
            name: 'BlogEdit',
            params: { blogId: id }
          })
        }
      })
    },
    createTag (id, tag) {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/blogTag/createBlogTag',
        params: {
          blogId: id,
          tag: tag
        }
      })
    }
  }
}
</script>

<style scoped>
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}

.el-tag + .el-tag {
  margin-left: 10px;
}

.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
</style>
