export function handleScroll () {
  const top = pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
  if (top > 250) {
    this.isFixed = 1
  } else if (top < 200) {
    this.isFixed = 0
  }
}

export function handleAnimate () {
  const top = pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
  const vh = document.documentElement.clientHeight
  const dom = document.querySelectorAll('.animate');
  [].slice.call(dom).forEach(v => {
    if (top + vh > v.offsetTop) {
      const delay = v.dataset.delay
      if (delay) {
        setTimeout(() => {
          v.style.opacity = 1
          v.classList.add(v.dataset.ani)
        }, delay)
      } else {
        v.style.opacity = 1
        v.classList.add(v.dataset.ani)
      }
    } else {
      v.classList.remove(v.dataset.ani)
      v.style.opacity = 0
    }
  })
}
